<template>
    <b-row>
        <b-col cols="12" class="d-flex align-items-stretch">
            <b-btn variant="primary" @click="openFileSelection">
                <feather-icon icon="UploadIcon" />
                Importer un fichier
            </b-btn>
            <b-alert show class="p-1 d-inline-block ml-2 flex-grow-1 m-0 text-center" :variant="file ? 'success' : 'primary'">{{ alertText }}</b-alert>
            <b-form-file ref="file-input" v-model="file" class="d-none" accept=".csv"></b-form-file>
        </b-col>
        <b-btn variant="success" class="mt-2 d-flex ml-auto mr-auto" :disabled="file == null" @click="openAlert">Ajouter</b-btn>
    </b-row>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        props: {
            confirmModalMessage: {
                type: String,
                default: 'Êtes-vous sûr de vouloir ajouter ces métriques ?',
            },
        },
        data() {
            return {
                file: null,
            };
        },
        computed: {
            ...mapGetters({
                modalContext: 'app/modalContext',
            }),
            alertText() {
                return this.file ? this.file.name : 'Aucun fichier importé';
            },
        },
        methods: {
            openFileSelection() {
                this.$refs['file-input'].$el.childNodes[0].click();
            },

            openAlert() {
                this.$bvModal
                    .msgBoxConfirm(this.confirmModalMessage, this.modalContext)
                    .then((confirmed) => {
                        if (confirmed) this.submit();
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            },
            submit() {
                this.$emit('on-submit', this.file);
            },
        },
    };
</script>

<style></style>
