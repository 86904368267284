<template>
    <div>
        <b-card title="Ajouter métriques">
            <file-input @on-submit="addMetrics"></file-input>
            <b-alert :show="showMetricsAlert" class="mt-2" :variant="messages.metrics.type" dismissible>
                <h4 class="alert-heading">{{ messages.metrics.type === 'danger' ? 'Server error' : 'Server response' }}</h4>
                <div class="p-1" style="white-space: pre">
                    {{ messages.metrics.message }}
                </div>
            </b-alert>
        </b-card>

        <b-card title="Ajouter topten">
            <file-input confirm-modal-message="Êtes-vous sûr de vouloir ajouter ces topten ?" @on-submit="addTopten"></file-input>
            <b-alert :show="showToptenAlert" class="mt-2" :variant="messages.topten.type" dismissible>
                <h4 class="alert-heading">{{ messages.topten.type === 'danger' ? 'Server error' : 'Server response' }}</h4>
                <div class="p-1" style="white-space: pre">
                    {{ messages.topten.message }}
                </div>
            </b-alert>
        </b-card>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import FileInput from './inport-file.vue';
    export default {
        components: { FileInput },
        data() {
            return {
                messages: {
                    topten: {
                        message: '',
                        type: '',
                    },
                    metrics: {
                        message: '',
                        type: '',
                    },
                },
            };
        },
        computed: {
            ...mapGetters({
                errorToast: 'app/errorToast',
                successToast: 'app/successToast',
            }),
            showMetricsAlert() {
                return this.messages.metrics.message !== undefined && this.messages.metrics.message !== '';
            },
            showToptenAlert() {
                return this.messages.topten.message !== undefined && this.messages.topten.message !== '';
            },
        },
        methods: {
            addMetrics(file) {
                console.log('file', file);
                this.messages.metrics.message = '';
                let formData = new FormData();
                formData.append('file', file);

                this.$root.toggleGlobalLoading(true);

                this.$store
                    .dispatch('app/addMetrics', formData)
                    .then((res) => {
                        this.$toast(this.successToast('Les métriques ont été uploadés'));
                        this.messages.metrics.message = res.data?.message;
                        this.messages.metrics.type = 'success';
                    })
                    .catch((e) => {
                        console.log(e);
                        this.messages.metrics.message = e.response?.data?.message;
                        this.messages.metrics.type = 'danger';
                        this.$toast(this.errorToast("Erreur lors de l'upload des métriques"));
                    })
                    .finally((e) => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },

            addTopten(file) {
                this.messages.topten.message = '';
                let formData = new FormData();
                formData.append('file', file);

                this.$root.toggleGlobalLoading(true);

                this.$store
                    .dispatch('app/addTopten', formData)
                    .then((res) => {
                        this.messages.topten.message = res.data?.message;
                        this.messages.topten.type = 'success';
                        this.$toast(this.successToast('Les topten ont été uploadés'));
                    })
                    .catch((e) => {
                        console.log(e);
                        this.messages.topten.message = e.response?.data?.message;
                        this.messages.metrics.type = 'danger';
                        this.$toast(this.errorToast("Erreur lors de l'upload des topten"));
                    })
                    .finally((e) => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },
        },
    };
</script>

<style lang="scss">
    .file-alert {
    }
</style>
